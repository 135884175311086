<template>
  <div class="vendedores-wrapper" id="vendedores" v-show="true">
    <section class="vendedores-hero">
      <div class="bb-container">
        <div class="vendedores-hero__content">
          <div class="vendedores-hero__info">
            <div class="vendedores-hero__pretitulo vendedores-gradient">
              HelloBB para Vendedores
            </div>
            <div class="vendedores-hero__titulo">
              La lista de deseos que te conecta con tus clientes
            </div>
            <div class="vendedores-hero__descripcion">
              Consigue más ventas y gana nuevos clientes con la mejor lista de deseos
              integrada en tu ecommerce.
            </div>
            <div class="vendedores-hero__actions">
              <a class="button button--accent" href="#vendedores-form">
                Solicitar información<i class="ml-2 uil uil-angle-right-b"></i>
              </a>
            </div>
          </div>
          <div class="vendedores-hero__thumb">
            <img
              src="../../assets/img/covers/cover-header-vendedores.png"
              alt="HelloBB Vendedores"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="vendedores-feat">
      <div class="bb-container">
        <div class="vendedores-feat__content">
          <div class="vendedores-feat__thumb">
            <img
              src="../../assets/img/logos/logo-ico-bg-cercles.png"
              alt="HelloBB Vendedores"
            />
          </div>
          <div class="vendedores-feat__titulo">
            Integra tus productos con la lista de deseos
            <span class="vendedores-gradient"> #1 de España </span>
          </div>
        </div>

        <div class="vendedores-feat__cols">
          <div class="vendedores-feat__col vendedores-col">
            <div class="vendedores-col__ico">
              <img
                src="../../assets/img/ico/ico-e-commerce-1.svg"
                alt="HelloBB Vendedores"
              />
            </div>
            <div class="vendedores-col__info">
              <div class="vendedores-col__titulo">En tu tienda</div>
              <div class="vendedores-col__descripcion">
                <p style="margin-bottom: 0">
                  Tus páginas de producto tendrán un botón para guardarlos directamente en
                  una lista de deseos HelloBB
                </p>
              </div>
            </div>
          </div>

          <div class="vendedores-feat__col vendedores-col">
            <div class="vendedores-col__ico">
              <img
                src="../../assets/img/ico/ico-e-commerce-2.svg"
                alt="HelloBB Vendedores"
              />
            </div>
            <div class="vendedores-col__info">
              <div class="vendedores-col__titulo">Fuera de tu tienda</div>
              <div class="vendedores-col__descripcion">
                <p>
                  Tus productos acompañarán a los usuarios que los han guardado y que usen HelloBB para hacer sus listas de deseos
                </p>
                <p style="margin-bottom: 0">
                  Todos los usuarios de HelloBB podrán descubrir tu marca desde nuestro catálogo
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="vendedores-usando" v-show="false">
      <div class="bb-container">
        <div class="vendedores-usando__content">
          <div class="vendedores-usando__titulo">Están usando HelloBB</div>
          <div class="vendedores-usando__items">
            <div class="vendedores-usando__item">
              <img
                src="../../assets/img/logos/logo-af-corte-ingles.svg"
                alt="HelloBB - El Corte Inglés"
              />
            </div>
            <div class="vendedores-usando__item">
              <img
                src="../../assets/img/logos/logo-af-amazon.svg"
                alt="HelloBB - Amazon"
              />
            </div>
            <div class="vendedores-usando__item">
              <img src="../../assets/img/logos/logo-af-ikea.svg" alt="HelloBB - Ikea" />
            </div>
            <div class="vendedores-usando__item">
              <img src="../../assets/img/logos/logo-af-etsy.svg" alt="HelloBB - Etsy" />
            </div>
            <div class="vendedores-usando__item">
              <img
                src="../../assets/img/logos/logo-af-tutete.png"
                alt="HelloBB - Tutete"
              />
            </div>
            <div class="vendedores-usando__item">
              <img
                src="../../assets/img/logos/logo-af-mundobebes.png"
                alt="HelloBB - Mundo Bebés"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="vendedores-2">
      <div class="bb-container">
        <div class="vendedores-2__titulos">
          <div class="vendedores-2__pretitulo">Dos partes que trabajan juntas</div>
          <div class="vendedores-2__titulo">
            HelloBB & <span class="vendedores-gradient">HelloBB para Vendedores</span>
          </div>
        </div>

        <div class="vendedores-2__content">
          <div class="vendedores-2__item vendedores-2__item--1">
            <div class="vendedores-2__item-titulo vendedores-gradient">
              HelloBB es una lista de deseos
            </div>
            <div class="vendedores-2__item-descripcion">
              multi-tienda e independiente. Miles de personas usan HelloBB a diario para tomar decisiones sobre qué necesitarán y conseguirlo.
            </div>
          </div>

          <div class="vendedores-2__item vendedores-2__item--2">
            <div class="vendedores-2__item-titulo">
              HelloBB para Vendedores es el puente que te conecta con esta comunidad.
            </div>
            <div class="vendedores-2__item-actions">
              <a class="button button--accent" href="#vendedores-form">
                Solicita más información<i class="ml-2 uil uil-angle-right-b"></i>
              </a>
            </div>
          </div>

          <!-- nou !!!  -->
          <div class="vendedores-2__item vendedores-2__item--img vendedores-2__item--3">
            <picture class="">
              <source
                srcset="../../assets/img/covers/cover-pareja-pensativa-540.jpg"
                media="(max-width: 400px)"
              />
              <source
                srcset="../../assets/img/covers/cover-pareja-pensativa-800.jpg"
                media="(max-width: 400px) and (min-device-pixel-ratio: 1.5)"
              />
              <source
                srcset="../../assets/img/covers/cover-pareja-pensativa-800.jpg"
                media="(max-width: 767px)"
              />
              <img
                loading="lazy"
                src="../../assets/img/covers/cover-pareja-pensativa-2x.jpg"
                alt="HelloBB es una lista de deseos"
              />
            </picture>
          </div>
          <div class="vendedores-2__item vendedores-2__item--img vendedores-2__item--4">
            <picture class="">
              <source
                srcset="
                  ../../assets/img/covers/cover-mujeres-consultando-ordenador-540.jpg
                "
                media="(max-width: 400px)"
              />
              <source
                srcset="
                  ../../assets/img/covers/cover-mujeres-consultando-ordenador-800.jpg
                "
                media="(max-width: 400px) and (min-device-pixel-ratio: 1.5)"
              />
              <source
                srcset="
                  ../../assets/img/covers/cover-mujeres-consultando-ordenador-800.jpg
                "
                media="(max-width: 767px)"
              />
              <img
                loading="lazy"
                src="../../assets/img/covers/cover-mujeres-consultando-ordenador-2x.jpg"
                alt="HelloBB para Vendedores es el puente que te conecta con esta comunidad."
              />
            </picture>
          </div>
          <div class="vendedores-2__ico">
            <img
              src="../../assets/img/logos/logo-ico-bg-badge.svg"
              alt="HelloBB - Vendedores"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="vendedores-historia">
      <div class="bb-container">
        <div class="vendedores-historia__content">
          <div class="vendedores-historia__titulos">
            <div class="vendedores-historia__pretitulo">Nuestra historia</div>
            <div class="vendedores-historia__titulo">
              Desde nuestros orígenes hemos...
            </div>
          </div>

          <div class="vendedores-historia__cards">
            <div class="vendedores-historia__card vendedores-card">
              <div class="vendedores-card__pretitulo">Ayudado a más de</div>
              <div class="vendedores-card__titulo">50.000</div>
              <div class="vendedores-card__descripcion">
                personas a hacer sus listas de deseos
              </div>
            </div>

            <div class="vendedores-historia__card vendedores-card">
              <div class="vendedores-card__pretitulo">Llegado a familias en...</div>
              <div class="vendedores-card__titulo">España, Francia e Italia</div>
            </div>

            <div class="vendedores-historia__card vendedores-card">
              <div class="vendedores-card__pretitulo">Gestionado más de</div>
              <div class="vendedores-card__titulo">15.000.000€</div>
              <div class="vendedores-card__descripcion">
                en regalos para nuestros usuarios
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="vendedores-marketing">
      <div class="bb-container">
        <div class="vendedores-marketing__titulo vendedores-gradient">
          Una lista de deseos que apoya tu estrategia de marketing.
        </div>
        <div class="vendedores-marketing__content">
          <div class="vendedores-marketing__item">
            <div class="vendedores-marketing__item-ico">
              <img
                src="../../assets/img/ico/ico-vendedores-estadisticas.svg"
                alt="HelloBB - Consigue más ventas"
              />
            </div>
            <div class="vendedores-marketing__item-desc">
              <div class="vendedores-marketing__item-titulo">Consigue más ventas</div>
              <div class="vendedores-marketing__item-descripcion">
                Ya sabes que no todas las compras suceden en la primera visita. Las listas
                de deseos son ideales para mantener el interés de esos usuarios que
                todavía no están listos para comprar.
              </div>
            </div>
          </div>

          <div class="vendedores-marketing__item">
            <div class="vendedores-marketing__item-ico">
              <img
                src="../../assets/img/ico/ico-vendedores-bombilla-euro.svg"
                alt="HelloBB - Genera nuevas oportunidades"
              />
            </div>
            <div class="vendedores-marketing__item-desc">
              <div class="vendedores-marketing__item-titulo">
                Genera nuevas oportunidades
              </div>
              <div class="vendedores-marketing__item-descripcion">
                Incluye tu marca y tus productos en el catálogo de HelloBB. Nuestros usuarios están activamente seleccionando su próximo producto favorito.
              </div>
            </div>
          </div>

          <div class="vendedores-marketing__item">
            <div class="vendedores-marketing__item-ico">
              <img
                src="../../assets/img/ico/ico-vendedores-movil.svg"
                alt="HelloBB - Acompaña a tus clientes potenciales"
              />
            </div>
            <div class="vendedores-marketing__item-desc">
              <div class="vendedores-marketing__item-titulo">
                Acompaña a tus clientes potenciales
              </div>
              <div class="vendedores-marketing__item-descripcion">
                HelloBB es una herramienta que miles de personas usan a diario. Si estás con nosotros estás con ellos.
              </div>
            </div>
          </div>

          <div class="vendedores-marketing__item">
            <div class="vendedores-marketing__item-ico">
              <img
                src="../../assets/img/ico/ico-vendedores-red.svg"
                alt="HelloBB - Accede a compradores que no esperabas"
              />
            </div>
            <div class="vendedores-marketing__item-desc">
              <div class="vendedores-marketing__item-titulo">
                Accede a compradores que no esperabas
              </div>
              <div class="vendedores-marketing__item-descripcion">
                Multiplica tus opciones de vender. Las compras de una lista de deseos
                representan en buena parte regalos por parte de familia o amigos que
                probablemente no conocían tu tienda.
              </div>
            </div>
          </div>

          <div class="vendedores-marketing__item">
            <div class="vendedores-marketing__item-ico">
              <img
                src="../../assets/img/ico/ico-vendedores-globe.svg"
                alt="HelloBB - Accede a nuevos mercados"
              />
            </div>
            <div class="vendedores-marketing__item-desc">
              <div class="vendedores-marketing__item-titulo">
                Accede a nuevos mercados
              </div>
              <div class="vendedores-marketing__item-descripcion">
                HelloBB está presente en varios países. Te ayudamos a despegar en nuevos
                mercados.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="vendedores-necesidades">
      <div class="bb-container">
        <div class="vendedores-necesidades__titulo vendedores-gradient">
          Con todo lo que tu tienda necesita
        </div>
        <div class="vendedores-necesidades__content">
          <div class="vendedores-necesidades__item">
            <div class="vendedores-necesidades__item-ico">
              <img
                src="../../assets/img/ico/ico-vendedores-estilo.svg"
                alt="HelloBB - Tu tienda, tu estilo"
              />
            </div>
            <div class="vendedores-necesidades__item-desc">
              <div class="vendedores-necesidades__item-titulo">Tu tienda, tu estilo</div>
              <div class="vendedores-necesidades__item-descripcion">
                Adapta los componentes de HelloBB al estilo de tu ecommerce.
              </div>
            </div>
          </div>

          <div class="vendedores-necesidades__item">
            <div class="vendedores-necesidades__item-ico">
              <img
                src="../../assets/img/ico/ico-vendedores-puzzle.svg"
                alt="HelloBB - Fácil de integrar"
              />
            </div>
            <div class="vendedores-necesidades__item-desc">
              <div class="vendedores-necesidades__item-titulo">Fácil de integrar</div>
              <div class="vendedores-necesidades__item-descripcion">
                Nuestra solución se adapta fácilmente a tus sistemas.
              </div>
            </div>
          </div>

          <div class="vendedores-necesidades__item">
            <div class="vendedores-necesidades__item-ico">
              <img
                src="../../assets/img/ico/ico-vendedores-cesta.svg"
                alt="HelloBB - Sin salir de tu tienda"
              />
            </div>
            <div class="vendedores-necesidades__item-desc">
              <div class="vendedores-necesidades__item-titulo">
                Sin salir de tu tienda
              </div>
              <div class="vendedores-necesidades__item-descripcion">
                Sin interrupciones ni redirecciones. Cuando estén en tu tienda, tus
                visitantes podrán añadir cosas a sus listas de deseos sin salir de ella.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="vendedores-porque">
      <div class="bb-container">
        <div class="vendedores-porque__titulo vendedores-gradient">
          ¿Por qué HelloBB no es como el resto de listas de deseos?
        </div>
        <div class="vendedores-porque__content">
          <div class="vendedores-porque__descripcion">
            <p>
              Muchas tiendas online permiten abrir una lista de deseos pero solo para
              guardar sus productos. La mayoría de personas no están interesadas. Aunque
              el catálogo de una tienda sea inmenso, difícilmente va a cubrir todos sus
              deseos.
            </p>
            <p>
              HelloBB es una lista independiente y multi-tienda, creada pensando en el
              comportamiento natural de los compradores y sus preferencias.
            </p>
            <p style="margin-bottom: 0">
              Visiten la tienda que visiten, HelloBB los acompaña creando un vínculo
              sólido entre ellos y las marcas que forman parte de sus listas.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="vendedores-planes">
      <div class="bb-container">
        <div class="vendedores-planes__content">
          <div class="vendedores-planes__titulos">
            <div class="vendedores-planes__titulo vendedores-gradient">
              Planes y precios a tu medida
            </div>
          </div>

          <div class="vendedores-planes__cards">
            <div class="vendedores-planes__card">
              <section class="plan-card plan-card--basico">
                <div class="plan-card__titulo">Básico</div>
                <div class="plan-card__precio">
                  <span class="plan-card__moneda"><sup>€</sup></span
                  >20 <span class="plan-card__periodicidad">/mes</span>
                </div>
                <div class="plan-card__descripcion-precio">192€ / año (-20%)</div>
                <!-- <div class="plan-card__descripcion">
                  Creación de listas de deseos desde la página de tu ecommerce.
                </div> -->
                <div class="plan-card__actions">
                  <a class="button button--primary button--block" href="#vendedores-form"
                    >Solicita información</a
                  >
                </div>
                <div class="plan-card__items">
                  <div class="plan-card__item">
                    Creación de listas de deseos desde tu ecommerce.
                  </div>
                  <div class="plan-card__item">
                    Botón “Añade a mi lista” para tus páginas de producto.
                  </div>
                  <div class="plan-card__item">
                    Adaptación a los estilos de tu ecommerce.
                  </div>
                  <div class="plan-card__item">
                    Visualización en las listas de deseos del logo de tu ecommerce en los
                    objetos añadidos de tu tienda.
                  </div>
                  <div class="plan-card__item">Informes de efectividad.</div>
                </div>
              </section>
            </div>

            <div class="vendedores-planes__card">
              <section class="plan-card plan-card--basico">
                <div class="plan-card__titulo">Plus</div>
                <div class="plan-card__precio">
                  <span class="plan-card__moneda">€</span>50
                  <span class="plan-card__periodicidad">/mes</span>
                </div>
                <div class="plan-card__descripcion-precio">480€ / año (-20%)</div>
                <!-- <div class="plan-card__descripcion">
                  Creación de listas de deseos desde tu ecommerce.
                </div> -->
                <div class="plan-card__actions">
                  <a class="button button--primary button--block" href="#vendedores-form">
                    Solicita información
                  </a>
                </div>
                <div class="plan-card__items">
                  <div class="plan-card__item">
                    Creación de listas de deseos desde tu ecommerce.
                  </div>
                  <div class="plan-card__item">
                    Botón “Añade a mi lista” para tus páginas de producto.
                  </div>
                  <div class="plan-card__item">
                    Adaptación a los estilos de tu ecommerce.
                  </div>
                  <div class="plan-card__item">
                    Visualización en las listas de deseos del logo de tu ecommerce en los
                    objetos añadidos de tu tienda.
                  </div>
                  <div class="plan-card__item">Informes de efectividad.</div>
                  <div class="plan-card__item">
                    Sección "Lista de deseos" en tu ecommerce con los productos añadidos
                    de tu tienda.
                  </div>
                  <div class="plan-card__item">
                    Inclusión de tus productos en el catálogo virtual de HelloBB. (máx 20
                    SKUs)
                  </div>
                  <div class="plan-card__item">
                    Promoción de tu marca y de tus productos estrella en las listas de
                    HelloBB.
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="vendedores-form" class="vendedores-contacto">
      <div class="bb-container bb-container--lista">
        <div class="vendedores-contacto__content">
          <div class="vendedores-contacto__titulos">
            <div class="vendedores-contacto__pretitulo">Solicitar información</div>
            <div class="vendedores-contacto__titulo vendedores-gradient">
              Contacta con nosotros para tener más información.
            </div>
          </div>

          <div class="vendedores-contacto__form">
            <div class="bb-form-fieldset">
              <InputText
                :disabled="requestInfoFormIsSaving"
                :errorLabel="companyError"
                :initialValue="empresa"
                @focus="companyError = ''"
                v-model="empresa"
                label="Empresa"
                placeholder="Empresa"
                required
              />
              <InputText
                :disabled="requestInfoFormIsSaving"
                :errorLabel="emailError"
                :initialValue="email"
                @focus="emailError = ''"
                v-model="email"
                label="Email"
                placeholder="Email"
                required
              />
              <InputTextArea
                :disabled="requestInfoFormIsSaving"
                :errorLabel="messageError"
                :initialValue="mensaje"
                @focus="messageError = ''"
                v-model="mensaje"
                label="Mensaje"
                placeholder="Mensaje"
                :rows="5"
                :maxlength="256"
                required
              />
            </div>

            <div class="vendedores-contacto__form-actions">
              <!--
                This is a good place to put the LoadingButton component, but
                since the submit button has flexible width (i.e. its width
                depends on its content, and its content depends on the
                language), then the LoadingButton would look out of place.

                But if the submit button can have consistent width (e.g. 100%
                of its container), then it should be fine.

                So if the LoadingButton is added, it could be something like:

                  <LoadingButton v-if="requestInfoFormIsSaving" />

                And add `v-else` to the submit button.
              -->

              <bbButton :disabled="requestInfoFormIsSaving" label="Solicita información" class="button--primary" @click="requestInfo()"></bbButton>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import InputText from "../../components/forms/InputText.vue";
import InputTextArea from "../../components/forms/InputTextArea.vue";
import bbButton from "../../components/buttons/bbButton.vue";
import LoadingButton from "../../components/LoadingButton";

export default {
  name: "vendedores",
  components: {
    InputText,
    InputTextArea,
    bbButton,
    LoadingButton,
  },
  metaInfo() {
    return {
      title: "HelloBB para Vendedores",
      meta: [
        {
          name: "description",
          content:
            "Consigue más ventas y gana nuevos clientes con una lista de deseos multi-tienda integrada en tu ecommerce.",
        },
      ],
    };
  },
  data() {
    return {
      empresa: "",
      email: "",
      mensaje: "",
      companyError: "",
      emailError: "",
      messageError: "",
      requestInfoFormIsSaving: false,
    };
  },
  computed: {
    requestInfoFormIsValid() {
      const {
        company,
        email,
        message,
      } = this.requestInfoPayload;

      return (
        company &&
        email &&
        message &&
        !this.companyError &&
        !this.emailError &&
        !this.messageError
      );
    },
    requestInfoPayload() {
      return {
        email: this.email?.trim() || "",
        company: this.empresa?.trim() || "",
        message: this.mensaje?.trim() || "",
      };
    },
  },
  methods: {
    validateRequestInfoForm() {
      const {
        email,
        company,
        message,
      } = this.requestInfoPayload;

      if (!company) {
        this.companyError = this.$t("generic.field_is_required", this.lang);
      }
      if (!email) {
        this.emailError = this.$t("generic.field_is_required", this.lang);
      } else if (!this.$utils.validateEmail(email)) {
        this.emailError = this.$t("login.invalidEmailFormat", this.lang);
      }
      if (!message) {
        this.messageError = this.$t("generic.field_is_required", this.lang);
      }

      return this.requestInfoFormIsValid;
    },
    async requestInfo() {
      this.requestInfoFormIsSaving = true;

      try {
        if (!this.validateRequestInfoForm()) {
          return;
        }

        await this.$store.dispatch("sellerRequestInfo", this.requestInfoPayload);

        this.clearRequestInfoForm();

        this.$router.replace({ name: "vendedoresThanks" });
      } finally {
        this.requestInfoFormIsSaving = false;
      }
    },
    clearRequestInfoForm() {
      this.email = "";
      this.empresa = "";
      this.mensaje = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
